import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type ActivityContext = {
  active: boolean;
};

export const ActivityContext = createContext<ActivityContext>({
  active: true,
});

export const useActivityContext = () => useContext(ActivityContext);

const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 min

export const ActivityProvider = ({ children }: { children: ReactNode }) => {
  const [active, setActive] = useState(true);
  // This ensures that the timeoutId value persists across renders, and also avoids unnecessary re-renders
  const timeoutId = useRef<ReturnType<typeof setTimeout> | undefined>();

  const resetTimer = () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    startTimer();
    setActive(true);
  };

  const startTimer = () => {
    timeoutId.current = setTimeout(() => {
      // requestAnimationFrame to ensure that updates are batched together and only applied once per frame, instead of on every render
      requestAnimationFrame(() => setActive(false));
    }, INACTIVITY_TIMEOUT);
  };

  useEffect(() => {
    // passive option where possible to improve scrolling performance
    document.addEventListener("mousemove", resetTimer, { passive: true });
    document.addEventListener("mousedown", resetTimer, { passive: true });
    document.addEventListener("keypress", resetTimer, { passive: true });
    document.addEventListener("touchmove", resetTimer, { passive: true });
    document.addEventListener("wheel", resetTimer, { passive: true });

    startTimer();

    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("mousedown", resetTimer);
      document.removeEventListener("keypress", resetTimer);
      document.removeEventListener("touchmove", resetTimer);
      document.removeEventListener("wheel", resetTimer);
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, []);

  return (
    <ActivityContext.Provider value={{ active }}>
      {children}
    </ActivityContext.Provider>
  );
};
