import { Validation } from "core/types";
import { ObservableValue } from "./Context";

export const VALUE_CHANGE_EVENT_TYPE = "VALUE_CHANGE" as const;
export const INITIAL_CHANGE_EVENT_TYPE = "INITIAL_CHANGE" as const;
export const SUBMIT_EVENT_TYPE = "SUBMIT" as const;
export const VALIDATION_FAILED_EVENT_TYPE = "VALIDATION_FAILED" as const;
export const RESET_VALIDATION_EVENT_TYPE = "RESET_VALIDATION" as const;

const EVENT_TYPES = [
  VALUE_CHANGE_EVENT_TYPE,
  INITIAL_CHANGE_EVENT_TYPE,
  SUBMIT_EVENT_TYPE,
  VALIDATION_FAILED_EVENT_TYPE,
  RESET_VALIDATION_EVENT_TYPE,
] as const;

export type FormStateEvent = (typeof EVENT_TYPES)[number];

export function createValueChangeEvent(
  newValue: any,
  statePath: string,
  validation: Validation,
): ObservableValue {
  return {
    type: VALUE_CHANGE_EVENT_TYPE,
    newValue,
    statePath,
    validation,
  };
}

export function createInitialChangeEvent(newInitial: any): ObservableValue {
  return {
    type: INITIAL_CHANGE_EVENT_TYPE,
    newValue: newInitial,
  };
}

export function createSubmitChangeEvent(
  clickEvent: React.MouseEvent<HTMLButtonElement> | undefined,
): ObservableValue {
  return {
    type: SUBMIT_EVENT_TYPE,
    clickEvent,
  };
}

export function createValidationFailedEvent(
  validation: Validation,
): ObservableValue {
  return {
    type: VALIDATION_FAILED_EVENT_TYPE,
    validation,
  };
}

export function createResetValidationEventType(
  paths?: ObservableValue["paths"],
): ObservableValue {
  return {
    type: RESET_VALIDATION_EVENT_TYPE,
    paths,
  };
}
