import { combineReducers, configureStore } from "@reduxjs/toolkit";
import api from "core/model/api";
import {
  setAuthAccessor,
  setTokenAccessor,
  setTokenExpiration,
} from "core/model/api/transport";
import { getTopLevelDomain } from "core/model/utils/urls";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice";
import settingsReducer from "./slices/settingsSlice";

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const persistConfig = {
  key: "root",
  storage,
};

const authPersistConfig = {
  key: "auth",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  settings: settingsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Required for redux-persist
    }),
  preloadedState: {
    settings: {
      language: process.env.LANG || getTopLevelDomain().toLowerCase(),
    },
  } as any,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

const setTokenFn = () => store.getState().auth.credentials?.token;

api.setTokenAccessor(setTokenFn);
api.setAuthAccessor(() => store.getState().auth.identification);
api.setTokenExpiration(() => store.getState().auth.credentials?.expiration);

setTokenAccessor(setTokenFn);
setAuthAccessor(() => store.getState().auth.identification);
setTokenExpiration(() => store.getState().auth.credentials?.expiration);
