import { ACCOUNT_TYPE_STAFF } from "core/consts";
import Config, { ENV_PRODUCTION } from "core/model/config";
import { usePrint } from "dsl/atoms/Contexts";
import lazy from "dsl/atoms/retry";
import { Suspense } from "react";
import { useLoggedInAccount } from "reduxentities/hooks";

const LanguageFab = lazy(() => import("./Fab"));

export default function LazyLanguageFab() {
  const print = usePrint();
  const loggedAccount = useLoggedInAccount();

  const isStaff = loggedAccount?.account_type === ACCOUNT_TYPE_STAFF;

  if (print || (Config.environment === ENV_PRODUCTION && !isStaff)) return null;

  return (
    <Suspense fallback={<div />}>
      <LanguageFab />
    </Suspense>
  );
}
