import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupportedLanguage } from "core/types";

export type SettingsState = {
  language: SupportedLanguage | undefined;
};

const initialState: SettingsState = {
  language: undefined,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    LANGUAGE_CHANGED: (state, action: PayloadAction<SupportedLanguage>) => {
      state.language = action.payload;
    },
  },
});

// Export actions
export const { LANGUAGE_CHANGED } = settingsSlice.actions;

// Export reducer
export default settingsSlice.reducer;
