import { QUERY_PROGRESS_FAILED } from "core/consts";
import { pathToUrl } from "core/model/utils/urls";
import { Banner } from "ds/ui";
import LinkV2 from "ds_legacy/components/LinkV2";
import { Z_INDEX_MAX, sizing } from "ds_legacy/materials/metrics";
import { useSealdProgress } from "dsl/atoms/Navbar/shared/ErrorBanner/useSealdProgress";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";

export const SealdWarningBanner = () => {
  const translations = useTranslations();
  const { checkSealdProgress, sealdProgress } = useSealdProgress();

  if (sealdProgress !== QUERY_PROGRESS_FAILED) return null;

  return (
    <div
      style={{
        top: sizing(6.5),
        left: "50%",
        position: "fixed",
        transform: "translateX(-50%)",
        zIndex: Z_INDEX_MAX,
      }}
    >
      <div className="mt-2 w-full">
        <Banner
          message={
            <TranslationComposition
              translations={
                translations.general.banner.cta.noSealdConnectionAlert
              }
              withOptions
            >
              {([before, link, after]) => (
                <>
                  {before}
                  <LinkV2
                    color="inherit"
                    underline="always"
                    href={pathToUrl({
                      pathname: "/diagnostic",
                      search: undefined,
                    })}
                  >
                    {link}
                  </LinkV2>
                  {after}
                </>
              )}
            </TranslationComposition>
          }
          heading={translations.general.banner.cta.noEncryptionAccessAlertTitle}
          color="danger"
          actions={[
            {
              id: "refresh",
              label: translations.actions.refresh,
              onClick: () => {
                checkSealdProgress();
              },
            },
          ]}
        />
      </div>
    </div>
  );
};
