import { useApolloClient } from "@apollo/client";
import { useEnvContext } from "context/EnvContext";
import { APP_CAREPROVIDER, APP_CLINIC } from "core/consts";
import { isDesignBatch1 } from "core/model/utils/featureFlags";
import SealdContextProvider from "core/seald/SealdContext";
import { AppType } from "core/types";
import SpinnerPage, {
  SpinnerPageContainer,
} from "ds_legacy/components/Spinner";
import { SealdWarningBanner } from "dsl/atoms/RefreshBanners/SealdWarningBanner";
import {
  useConnecterWithLoggedAccount,
  useOnLogout,
} from "reduxentities/hooks";

export default function WebSealdContextProvider({
  app,
  children,
}: {
  app: AppType;
  children: React.ReactNode;
}) {
  const loggedAccount = useConnecterWithLoggedAccount({ cacheOnly: true });
  const envContext = useEnvContext();
  const onLogout = useOnLogout();
  const client = useApolloClient();

  return (
    <SealdContextProvider
      loggedAccount={loggedAccount}
      envContext={envContext}
      app={app}
      onLogout={onLogout}
      apolloClient={client}
      Loading={
        <SpinnerPageContainer>
          <SpinnerPage id="seald-import-identify" />
        </SpinnerPageContainer>
      }
    >
      {([APP_CAREPROVIDER, APP_CLINIC] as AppType[]).includes(app) &&
        !isDesignBatch1() && <SealdWarningBanner />}
      {children}
    </SealdContextProvider>
  );
}
