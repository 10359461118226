import { PayloadAction } from "@reduxjs/toolkit";
import { CAREPROVIDER_INACTIVE } from "core/consts";
import { getTopLevelDomain } from "core/model/utils/urls";
import {
  AccountInIdentification,
  AccountType,
  CareproviderInRole,
  CareproviderRoles,
  CareseekerInRole,
  CareseekerRoles,
} from "core/types";
import { fromJS } from "immutable";
import { AuthState, StateIdentification } from "../slices/authSlice";

export function getFirstSeekerRole(roles: Array<CareseekerRoles>) {
  if (!roles || roles.length == 0) return null;

  const sortedRoles = roles.sort(
    (a, b) => (a?.careseeker?.id || -1) - (b?.careseeker?.id || -1),
  );
  return sortedRoles[0];
}

export const findMatchingCareproviderFromState = (
  roles: CareproviderRoles[],
  state: AuthState,
) => {
  if (!roles || roles.length == 0 || !state?.careprovider) return null;

  return (
    roles.find((c) => c?.careprovider?.id == state.careprovider) &&
    state.careprovider
  );
};

export const findMatchingCareseekerFromState = (
  roles: CareseekerRoles[],
  state: AuthState,
) => {
  if (!roles?.length || !state?.careseeker) return null;

  return (
    roles.find((c) => c?.careseeker?.id == state.careseeker) && state.careseeker
  );
};

type RequiredWithException<T, K extends keyof T> = Required<Omit<T, K>> &
  Partial<Pick<T, K>>;

export function stripCareproviderRoles(
  careproviderRoles: Array<CareproviderRoles>,
): Array<
  CareproviderRoles & {
    careprovider: RequiredWithException<CareproviderInRole, "product_features">;
  }
> {
  if (!careproviderRoles) return careproviderRoles;

  return careproviderRoles.map((c) => ({
    id: c.id,
    roles: c.roles,
    careprovider: {
      id: c.careprovider?.id ?? -1,
      name: c.careprovider?.name ?? "",
      address: c.careprovider?.address ?? {},
      status: c.careprovider?.status ?? CAREPROVIDER_INACTIVE,
    },
  }));
}

export function stripCareseekerRoles(
  careseekerRoles: Array<CareseekerRoles>,
): Array<CareproviderRoles & { careseeker: Required<CareseekerInRole> }> {
  if (!careseekerRoles) return careseekerRoles;
  return careseekerRoles.map((c) => ({
    id: c.id,
    roles: c.roles,
    careseeker: {
      __typename: "Careseeker",
      id: c.careseeker?.id ?? -1,
      name: c.careseeker?.name ?? "",
      address: c.careseeker?.address ?? {},
      status: c.careseeker?.status ?? -1,
      patient_types: c.careseeker?.patient_types ?? [],
    },
  }));
}

export function cleanIdentification(
  identification: StateIdentification,
): StateIdentification & { account: Required<AccountInIdentification> } {
  const account = identification.account;

  return {
    ...identification,
    careprovider_roles: stripCareproviderRoles(
      identification.careprovider_roles,
    ),
    careseeker_roles: stripCareseekerRoles(identification.careseeker_roles),
    account: {
      __typename: "Account",
      id: account.id,
      account_type: account.account_type ?? (-1 as AccountType),
      email: account.email ?? "",
      first_name: account.first_name ?? "",
      last_name: account.last_name ?? "",
      phone: account.phone ?? "",
    },
  };
}

export function setCountry(country: string | undefined, state: AuthState) {
  const immutableState = fromJS(state);

  const reduxCountry = country || state?.country || getTopLevelDomain();

  return immutableState.setIn(["country"], reduxCountry).toJS();
}

export function setAuthState(
  state: AuthState = {
    credentials: { token: undefined },
    identification: undefined,
  },
  action: PayloadAction<AnyObject, string>,
) {
  const immutableState = fromJS(state);
  return immutableState
    .set("version", action.payload.version)
    .setIn(["credentials", "token"], action.payload.token)
    .setIn(["credentials", "auth_type"], action.payload.auth_type ?? "password")
    .setIn(["credentials", "token_type"], action.payload.token_type)
    .setIn(["credentials", "expiration"], action.payload.expiration)
    .set(
      "identification",
      // Browsers have a limit on how much they can store in the local storage (around 25mb)
      // However the account used for storing identification can have a lot of data
      // Specially in testing envs, with numerous devices, roles, and associated accounts
      // For that reason, the data needs to be cleaned, otherwise auth with not be persisted
      cleanIdentification(action.payload.identification),
    )
    .toJS();
}

export function setCareseekerRoles(
  state: AuthState = { identification: undefined },
  action: PayloadAction<AnyObject, string>,
) {
  const immutableState = fromJS(state);

  return immutableState
    .set(
      "identification",
      cleanIdentification({
        ...state.identification,
        account: action.payload.identification.account,
        careseeker_roles: action.payload.identification.careseeker_roles,
      } as StateIdentification),
    )
    .toJS();
}

export function setProviderSearchCareseeker(
  state: AuthState = { identification: undefined },
  action: PayloadAction<AnyObject, string>,
) {
  const immutableState = fromJS(state);

  return immutableState
    .setIn(
      ["careseeker"],
      action.payload.identification.careseeker_roles[0].careseeker.id,
    )
    .set(
      ["careseeker_name"],
      action.payload.identification.careseeker_roles[0].careseeker.name,
    )
    .setIn(
      ["identification", "active_roles"],
      action.payload.identification.careseeker_roles,
    )
    .setIn(
      ["active_roles"],
      action.payload.identification.careseeker_roles.roles,
    )
    .toJS();
}

export function setCareseeker(
  careseekerId: number | undefined,
  careseekerName: string | undefined,
  state: AuthState,
) {
  if (!careseekerId) return state;

  const immutableState = fromJS(state);

  const careseekerRoles = immutableState.getIn([
    "identification",
    "careseeker_roles",
  ]);

  const activeRoles = careseekerRoles
    ? careseekerRoles.find((r: any) => r.get("id") === careseekerId)
    : {};

  return immutableState
    .setIn(["careseeker"], careseekerId)
    .set(["careseeker_name"], careseekerName)
    .setIn(["identification", "active_roles"], activeRoles)
    .setIn(["active_roles"], activeRoles.get?.("roles") || [])
    .toJS();
}

export function setCareprovider({
  careproviderId,
  careproviderName,
  state,
}: {
  careproviderId: number;
  careproviderName?: string;
  state: AuthState;
}) {
  if (!careproviderId) return state;

  const immutableState = fromJS(state);

  const careproviderRoles = immutableState.getIn([
    "identification",
    "careprovider_roles",
  ]);

  const activeRoles = careproviderRoles?.find(
    (r: any) => r.get("id") === careproviderId,
  );
  const careproviderNameFromRoles = activeRoles?.getIn([
    "careprovider",
    "name",
  ]);

  return immutableState
    .setIn(["careprovider"], careproviderId)
    .set(["careprovider_name"], careproviderName || careproviderNameFromRoles)
    .setIn(["identification", "active_roles"], activeRoles ?? {})
    .setIn(["active_roles"], activeRoles?.get("roles") ?? [])
    .toJS();
}
