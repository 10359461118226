export const PARAM = "([^/]+)";
export const PARAMS = {
  ACCOUNT_ID: ":account_id",
  AUCTION_ID: ":auction_id",
  AUCTION_REQUEST_ID: ":auction_request_id",
  CAREPROVIDER_ID: ":careprovider_id",
  CARESEEKER_ID: ":careseeker_id",
  CONSULTANT_ID: ":consultant_id",
  PATIENT_ID: ":patient_id",
  PROVIDER_SEARCH: ":provider_search",
  SECTION: ":section",
  TOKEN: ":token",
  FORM: ":form",
} as const;

const CATEGORIES: Record<string, Capitalize<string>> = {
  ASSESSMENT: "Assessment",
  AUCTION: "Auction",
  AUTH: "Auth",
  CAREPROVIDER: "Careprovider",
  CLINIC: "Clinic",
  DASHBOARD: "Dashboard",
  ONBOARDING: "Onboarding",
  PASSWORD_RESET: "Password Reset",
  PATIENT: "Patient",
  PROVIDER_SEARCH: "Provider Search",
  REQUEST: "Request",
  SETTINGS: "Settings",
  SPN_PROVIDER: "SPN Provider",
} as const;

/**
 * The page_name property used for tracking page views is a combination of the page category and page name.
 * For example, if the page category is "Assessment" and the page name is "Medical",
 * the resulting page name value would be "Assessment Medical".
 * Each word must be capitalized.
 *
 */

const PAGES = {
  APP: {
    ASSESSMENT_ELECTIVE_REHAB_INFO: `${CATEGORIES.ASSESSMENT} Elective Rehab Info`,
    ASSESSMENT_ELECTIVE_REHAB_MEDICAL: `${CATEGORIES.ASSESSMENT} Elective Rehab Medical`,
    ASSESSMENT_HOME_CARE_INFO: `${CATEGORIES.ASSESSMENT} Home Care Info`,
    ASSESSMENT_HOME_CARE_SEARCH: `${CATEGORIES.ASSESSMENT} Home Care Search`,
    ASSESSMENT_INFO: `${CATEGORIES.ASSESSMENT} Info`,
    ASSESSMENT_MEDICAL_SUPPLIES_CATALOGUE: `${CATEGORIES.ASSESSMENT} Medical Supplies`,
    ASSESSMENT_MEDICAL_SUPPLIES_INFO: `${CATEGORIES.ASSESSMENT} Medical Supplies Info`,
    ASSESSMENT_MEDICAL: `${CATEGORIES.ASSESSMENT} Medical`,
    ASSESSMENT_MENTAL: `${CATEGORIES.ASSESSMENT} Mental`,
    ASSESSMENT_MOBILE_SHORT: `${CATEGORIES.ASSESSMENT} Mobile Short`,
    ASSESSMENT_MOBILITY: `${CATEGORIES.ASSESSMENT} Mobility`,
    ASSESSMENT_NEEDS: `${CATEGORIES.ASSESSMENT} Needs`,
    ASSESSMENT_SHORT_REHAB_INFO: `${CATEGORIES.ASSESSMENT} Rehab Short Info`,
    ASSESSMENT_SHORT_REHAB_MEDICAL: `${CATEGORIES.ASSESSMENT} Rehab Short Medical`,
    ASSESSMENT_STEP: `${CATEGORIES.ASSESSMENT} Step`,
    ASSESSMENT_TRANSFER: `${CATEGORIES.ASSESSMENT} Transfer`,
    ASSESSMENT_TRANSPORT_INFO: `${CATEGORIES.ASSESSMENT} Transport Info`,
    ASSESSMENT_TRANSPORT_SEARCH: `${CATEGORIES.ASSESSMENT} Transport Search`,
    ASSESSMENT_TRANSPORT: `${CATEGORIES.ASSESSMENT} Transport`,
    AUTH_LOGIN: `${CATEGORIES.AUTH} Login`,
    AUTH_SSO_LOGIN: `${CATEGORIES.AUTH} SSO Login`,
    AUTH_PASSWORD_RESET: `${CATEGORIES.AUTH} Reset Password`,
    CLINIC_CHANGE_PASSWORD: `${CATEGORIES.CLINIC} Change Password`,
    CLINIC_DEBUG_ON_PREMISE_IMPORTS: `${CATEGORIES.CLINIC} Debug OnPremise Imports`,
    CLINIC_DEBUG_ON_PREMISE_INSTALL: `${CATEGORIES.CLINIC} Debug OnPremise Install`,
    CLINIC_DEBUG_ON_PREMISE_TEST: `${CATEGORIES.CLINIC} Debug OnPremise Test`,
    CLINIC_PRINTABLE_DISCHARGE: `${CATEGORIES.CLINIC} Printable Discharge`,
    CLINIC_PRINTABLE_SEARCH_PANEL: `${CATEGORIES.CLINIC} Printable SearchPanel`,
    CLINIC_REPORT: `${CATEGORIES.CLINIC} Report`,
    CLINIC_USERS: `${CATEGORIES.CLINIC} User Management`,
    DASHBOARD_ACTIVE: `${CATEGORIES.DASHBOARD} Active`,
    DASHBOARD_ARCHIVED: `${CATEGORIES.DASHBOARD} Archived`,
    DASHBOARD_DRAFT: `${CATEGORIES.DASHBOARD} Draft`,
    DASHBOARD_FINISHED: `${CATEGORIES.DASHBOARD} Finished`,
    DASHBOARD_PRINTABLE: `${CATEGORIES.DASHBOARD} Printable`,
    PATIENT_BARTHEL_INDEX: `${CATEGORIES.PATIENT} Barthel Index`,
    PATIENT_CREATE: `${CATEGORIES.PATIENT} Create`,
    PATIENT_EARLY_REHAB_INDEX: `${CATEGORIES.PATIENT} Early Rehab Index`,
    PATIENT_FILES: `${CATEGORIES.PATIENT} Files`,
    PATIENT_INSURANCE: `${CATEGORIES.PATIENT} Insurance`,
    PATIENT_MAP: `${CATEGORIES.PATIENT} Map`,
    PATIENT_PROFILE: `${CATEGORIES.PATIENT} Profile`,
    PATIENT_REHAB_FORMS_HOME: `${CATEGORIES.PATIENT} Rehab Forms Home`,
    PATIENT_REHAB_FORMS: `${CATEGORIES.PATIENT} Rehab Forms`,
    PATIENT_SEARCH: `${CATEGORIES.PATIENT} Search`,
    PATIENT_TIMELINE: `${CATEGORIES.PATIENT} Timeline`,
    PATIENT_TRANSITIONAL_CARE: `${CATEGORIES.PATIENT} Transitional Care`,
    REQUEST_DETAILS: `${CATEGORIES.REQUEST} Details`,
  },
  B2C: {
    CAREPROVIDER_DETAILS: `${CATEGORIES.CAREPROVIDER} Details`,
    DASHBOARD: `${CATEGORIES.DASHBOARD} Dashboard`,
    ONBOARDING_CARETERM: `${CATEGORIES.ONBOARDING} Care Term`,
    ONBOARDING_CARETYPE: `${CATEGORIES.ONBOARDING} Care Type`,
    ONBOARDING_LOADING: `${CATEGORIES.ONBOARDING} Loading`,
    ONBOARDING_LOCATION: `${CATEGORIES.ONBOARDING} Location`,
    ONBOARDING_STARTDATE: `${CATEGORIES.ONBOARDING} Start Date`,
    ONBOARDING_TARGET: `${CATEGORIES.ONBOARDING} Target`,
    ONBOARDING_WELCOME: `${CATEGORIES.ONBOARDING} Welcome`,
    ONBOARDING_DAILY_LIVING_SUPPORT_SERVICES: `${CATEGORIES.ONBOARDING} Onboarding Daily Support Services Info`,
    SPN_PROVIDER_DETAILS: `${CATEGORIES.SPN_PROVIDER} Details`,
  },
  PROVIDER_SEARCH: {
    ACCOUNTS: `${CATEGORIES.PROVIDER_SEARCH} My Account`,
    ACTIVATION_WIZARD: `${CATEGORIES.PROVIDER_SEARCH} Activation Wizard`,
    AUCTION_REQUEST: `${CATEGORIES.PROVIDER_SEARCH} Request Details`,
    CONSULTANT_DETAILS_PRINT: `${CATEGORIES.PROVIDER_SEARCH} Consultant Details Print`,
    CONSULTANT_DETAILS: `${CATEGORIES.PROVIDER_SEARCH} Consultant Details`,
    GLOSSARY: `${CATEGORIES.PROVIDER_SEARCH} Glossary`,
    LISTINGS_PRINT: `${CATEGORIES.PROVIDER_SEARCH} Home Page Print`,
    LISTINGS: `${CATEGORIES.PROVIDER_SEARCH} Home Page`,
    LOGIN_WIZARD: `${CATEGORIES.PROVIDER_SEARCH} Login Wizard`,
    LOGOUT: `${CATEGORIES.PROVIDER_SEARCH} Logout`,
    PATIENT_DASHBOARD: `${CATEGORIES.PROVIDER_SEARCH} Search Dashboard`,
    PROVIDER_DETAILS_PRINT: `${CATEGORIES.PROVIDER_SEARCH} Provider Details Print`,
    PROVIDER_DETAILS: `${CATEGORIES.PROVIDER_SEARCH} Provider Details`,
    TABLE_OF_CONTENTS: `${CATEGORIES.PROVIDER_SEARCH} Table of Contents`,
    ZIPCODE_SELECT: `${CATEGORIES.PROVIDER_SEARCH} Zipcode Select`,
  },
  RECEIVER: {
    AUTH_LOGIN: `${CATEGORIES.AUTH} Login`,
    AUTH_PASSWORD_RESET: `${CATEGORIES.AUTH} Password Reset`,
    CAREPROVIDER_NOTIFICATION_PANEL: `${CATEGORIES.CAREPROVIDER} Notification Panel`,
    CAREPROVIDER_PASSWORD_CHANGE: `${CATEGORIES.CAREPROVIDER} Change Password`,
    CAREPROVIDER_PROFILE: `${CATEGORIES.CAREPROVIDER} Profile`,
    DASHBOARD_ATTRIBUTED: `${CATEGORIES.DASHBOARD} Attributed`,
    DASHBOARD_DIRECT: `${CATEGORIES.DASHBOARD} Direct`,
    DASHBOARD_IN_PROGRESS: `${CATEGORIES.DASHBOARD} In Progress`,
    DASHBOARD_NEW: `${CATEGORIES.DASHBOARD} New`,
    DASHBOARD_OTHER: `${CATEGORIES.DASHBOARD} Other`,
    OB_PERSONAL_CONGRATS: `${CATEGORIES.ONBOARDING} Personal Congratulations`,
    OB_PERSONAL_PASSWORD: `${CATEGORIES.ONBOARDING} Personal Password`,
    OB_PROVIDER_ACCOUNT: `${CATEGORIES.ONBOARDING} Provider Account`,
    OB_PROVIDER_COMPANY: `${CATEGORIES.ONBOARDING} Provider Company`,
    OB_PROVIDER_CONGRATS: `${CATEGORIES.ONBOARDING} Provider Congratulations`,
    OB_PROVIDER_INFO: `${CATEGORIES.ONBOARDING} Provider Additional Info`,
    OB_PROVIDER_PASSWORD: `${CATEGORIES.ONBOARDING} Provider Password`,
    OB_PROVIDER_SERVICES: `${CATEGORIES.ONBOARDING} Provider Services`,
    ONBOARDING_COMPANY: `${CATEGORIES.ONBOARDING} Company`,
    ONBOARDING_PERSONAL: `${CATEGORIES.ONBOARDING} Personal`,
    ONBOARDING_PROVIDER: `${CATEGORIES.ONBOARDING} Provider Welcome`,
    REQUEST_BARTHEL_INDEX: `${CATEGORIES.REQUEST} Barthel Index`,
    REQUEST_DETAILS: `${CATEGORIES.REQUEST} Details`,
    REQUEST_EARLY_REHAB: `${CATEGORIES.REQUEST} Early Rehab Index`,
    SETTINGS_ACCOUNT: `${CATEGORIES.SETTINGS} Account`,
    SETTINGS_CAPACITY: `${CATEGORIES.SETTINGS} Capacity`,
    SETTINGS_EXTENSIONS: `${CATEGORIES.SETTINGS} Extensions`,
    SETTINGS_LEGAL: `${CATEGORIES.SETTINGS} Legal`,
    SETTINGS_NOTIFICATIONS: `${CATEGORIES.SETTINGS} Notifications`,
    SETTINGS_PARTNER_CLINICS: `${CATEGORIES.SETTINGS} Partner Clinics`,
    SETTINGS_PROFILE: `${CATEGORIES.SETTINGS} Company Profile`,
    SETTINGS_PWA_BETA: `${CATEGORIES.SETTINGS} PWA`,
    SETTINGS_SEARCH: `${CATEGORIES.SETTINGS} Search`,
    SETTINGS_SERVICES: `${CATEGORIES.SETTINGS} Services`,
    SETTINGS_TEAM: `${CATEGORIES.SETTINGS} Team`,
  },
  SHARED: {
    RESET: `${CATEGORIES.PASSWORD_RESET}`,
  },
} as const;

const PATHS = {
  APP: {
    ASSESSMENT_ELECTIVE_REHAB_INFO: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/elective-rehab-info`,
    ASSESSMENT_ELECTIVE_REHAB_MEDICAL: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/elective-rehab-medical`,
    ASSESSMENT_HOME_CARE_INFO: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/home-care-info`,
    ASSESSMENT_HOME_CARE_SEARCH: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/home-care-search`,
    ASSESSMENT_INFO: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/info`,
    ASSESSMENT_MEDICAL_SUPPLIES_CATALOGUE: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/medical-supplies-catalogue`,
    ASSESSMENT_MEDICAL_SUPPLIES_INFO: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/medical-supplies-info`,
    ASSESSMENT_MEDICAL: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/medical`,
    ASSESSMENT_MENTAL: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/mental`,
    ASSESSMENT_MOBILE_SHORT: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/mobile-short`,
    ASSESSMENT_MOBILITY: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/mobility`,
    ASSESSMENT_NEEDS: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/needs`,
    ASSESSMENT_SHORT_REHAB_INFO: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/rehab-short-info`,
    ASSESSMENT_SHORT_REHAB_MEDICAL: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/rehab-short-medical`,
    ASSESSMENT_STEP: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/step`,
    ASSESSMENT_TRANSFER: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/transfer`,
    ASSESSMENT_TRANSPORT_INFO: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/transport-info`,
    ASSESSMENT_TRANSPORT_SEARCH: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/transport-search`,
    ASSESSMENT_TRANSPORT: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/assessing/transport`,
    AUTH_LOGIN: `/auth`,
    AUTH_SSO_LOGIN: `/auth/${PARAMS.TOKEN}`,
    AUTH_PASSWORD_RESET: `/app/reset/${PARAM}`,
    CLINIC_CHANGE_PASSWORD: "/app/clinic/dashboard/my_password_change",
    CLINIC_DEBUG_ON_PREMISE_IMPORTS:
      "/app/clinic/dashboard/onpremsetup/imports",
    CLINIC_DEBUG_ON_PREMISE_INSTALL:
      "/app/clinic/dashboard/onpremsetup/install",
    CLINIC_DEBUG_ON_PREMISE_TEST: "/app/clinic/dashboard/onpremsetup/test",
    CLINIC_PRINTABLE_DISCHARGE: `/app/clinic/discharge/${PARAMS.PATIENT_ID}/${PARAMS.AUCTION_ID}`,
    CLINIC_PRINTABLE_SEARCH_PANEL: `/app/clinic/printableSearchPanel/${PARAMS.PATIENT_ID}/${PARAMS.AUCTION_ID}`,
    CLINIC_REPORT: "/app/clinic/dashboard/report",
    CLINIC_USERS: "/app/clinic/dashboard/users",
    DASHBOARD_ACTIVE: "/app/clinic/dashboard/todo/active",
    DASHBOARD_ARCHIVED: "/app/clinic/dashboard/todo/archived",
    DASHBOARD_DRAFT: "/app/clinic/dashboard/todo/draft",
    DASHBOARD_FINISHED: "/app/clinic/dashboard/todo/finished",
    DASHBOARD_PRINTABLE: `/app/clinic/printableDashboard/${PARAMS.CARESEEKER_ID}`,
    PATIENT_BARTHEL_INDEX: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/barthel-index`,
    PATIENT_CREATE: "/app/clinic/dashboard/patient/new",
    PATIENT_EARLY_REHAB_INDEX: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/early-rehab-index`,
    PATIENT_FILES: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/files`,
    PATIENT_INSURANCE: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/insurance`,
    PATIENT_MAP: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/map`,
    PATIENT_PROFILE: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/profile`,
    PATIENT_REHAB_FORMS: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/rehab-forms/${PARAMS.FORM}`,
    PATIENT_REHAB_FORMS_HOME: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/rehab-forms`,
    PATIENT_SEARCH: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/search`,
    PATIENT_TIMELINE: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/timeline`,
    PATIENT_TRANSITIONAL_CARE: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/transitional`,
    REQUEST_DETAILS: `/app/clinic/dashboard/patient/${PARAMS.PATIENT_ID}/auction/${PARAMS.AUCTION_ID}/request/${PARAMS.AUCTION_REQUEST_ID}`,
  },
  B2C: {
    CAREPROVIDER_DETAILS: `/b2c/provider/${PARAM}`,
    DASHBOARD: "/b2c/dashboard",
    ONBOARDING_CARETERM: "/b2c/onboarding/careTerm",
    ONBOARDING_CARETYPE: "/b2c/onboarding/careType",
    ONBOARDING_LOADING: "/b2c/onboarding/loading",
    ONBOARDING_LOCATION: "/b2c/onboarding/location",
    ONBOARDING_STARTDATE: "/b2c/onboarding/startdate",
    ONBOARDING_TARGET: "/b2c/onboarding/target",
    ONBOARDING_WELCOME: "/b2c/onboarding/welcome",
    ONBOARDING_DAILY_LIVING_SUPPORT_SERVICES:
      "/b2c/onboarding/dailyLivingSupportServices",
    SPN_PROVIDER_DETAILS: `/b2c/spn/${PARAM}`,
  },
  PROVIDER_SEARCH: {
    ACCOUNTS: `/providersearch/${PARAMS.PROVIDER_SEARCH}/account/${PARAMS.ACCOUNT_ID}`,
    ACTIVATION_WIZARD: `/providersearch/activationwizard`,
    AUCTION_REQUEST: `/providersearch/${PARAMS.PROVIDER_SEARCH}/request/${PARAMS.AUCTION_REQUEST_ID}`,
    CONSULTANT_DETAILS_PRINT: `/providersearch/${PARAMS.PROVIDER_SEARCH}/print-consultant/${PARAMS.CONSULTANT_ID}`,
    CONSULTANT_DETAILS: `/providersearch/${PARAMS.PROVIDER_SEARCH}/consultant/${PARAMS.CONSULTANT_ID}`,
    GLOSSARY: `/providersearch/${PARAMS.PROVIDER_SEARCH}/glossary/${PARAMS.SECTION}`,
    LISTINGS_PRINT: `/providersearch/${PARAMS.PROVIDER_SEARCH}/printable-listing`,
    LISTINGS: `/providersearch/${PARAMS.PROVIDER_SEARCH}/listing`,
    LOGIN_WIZARD: `/providersearch/loginwizard`,
    LOGOUT: `logout`,
    PATIENT_DASHBOARD: `/providersearch/${PARAMS.PROVIDER_SEARCH}/dashboard/patient/${PARAMS.PATIENT_ID}`,
    PROVIDER_DETAILS_PRINT: `/providersearch/${PARAMS.PROVIDER_SEARCH}/print-provider/${PARAMS.CAREPROVIDER_ID}`,
    PROVIDER_DETAILS: `/providersearch/${PARAMS.PROVIDER_SEARCH}/provider/${PARAMS.CAREPROVIDER_ID}`,
    TABLE_OF_CONTENTS: `/providersearch/${PARAMS.PROVIDER_SEARCH}/tableofcontents`,
    ZIPCODE_SELECT: `/providersearch/${PARAMS.PROVIDER_SEARCH}`,
  },
  RECEIVER: {
    AUTH_LOGIN: `/careprovider/auth`,
    AUTH_PASSWORD_RESET: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/reset`,
    CAREPROVIDER_NOTIFICATION_PANEL: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/notification_panel`,
    CAREPROVIDER_PASSWORD_CHANGE: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/my_password_change`,
    CAREPROVIDER_PROFILE: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/profile`,
    DASHBOARD_ATTRIBUTED: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/requests/attributed`,
    DASHBOARD_DIRECT: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/requests/direct`,
    DASHBOARD_IN_PROGRESS: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/requests/in_progress`,
    DASHBOARD_NEW: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/requests/new`,
    DASHBOARD_OTHER: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/requests/other`,
    OB_PERSONAL_CONGRATS: `/careprovider/newonboarding/personal/${PARAM}/congratulations`,
    OB_PERSONAL_PASSWORD: `/careprovider/newonboarding/personal/${PARAM}/password`,
    OB_PROVIDER_ACCOUNT: `/careprovider/newonboarding/${PARAM}/account`,
    OB_PROVIDER_COMPANY: `/careprovider/newonboarding/${PARAM}/company`,
    OB_PROVIDER_CONGRATS: `/careprovider/newonboarding/${PARAM}/congratulations`,
    OB_PROVIDER_INFO: `/careprovider/newonboarding/${PARAM}/additionalinformation`,
    OB_PROVIDER_PASSWORD: `/careprovider/newonboarding/${PARAM}/password`,
    OB_PROVIDER_SERVICES: `/careprovider/newonboarding/${PARAM}/services`,
    ONBOARDING_COMPANY: `/careprovider/companyonboarding/${PARAM}`,
    ONBOARDING_PERSONAL: `/careprovider/newonboarding/personal/${PARAM}`,
    ONBOARDING_PROVIDER: `/careprovider/newonboarding/${PARAM}`,
    REQUEST_BARTHEL_INDEX: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/requests/${PARAMS.AUCTION_REQUEST_ID}/barthel-index`,
    REQUEST_DETAILS: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/requests/${PARAMS.AUCTION_REQUEST_ID}`,
    REQUEST_EARLY_REHAB: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/requests/${PARAMS.AUCTION_REQUEST_ID}/early-rehab-index`,
    SETTINGS_ACCOUNT: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/account`,
    SETTINGS_CAPACITY: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/capacity`,
    SETTINGS_EXTENSIONS: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/extensions`,
    SETTINGS_LEGAL: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/legal_documents`,
    SETTINGS_NOTIFICATIONS: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/notifications`,
    SETTINGS_PARTNER_CLINICS: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/partner_clinics`,
    SETTINGS_PROFILE: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/profile`,
    SETTINGS_PWA_BETA: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/pwa`,
    SETTINGS_SEARCH: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/search`,
    SETTINGS_SERVICES: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/services`,
    SETTINGS_TEAM: `/careprovider/dashboard/${PARAMS.CAREPROVIDER_ID}/settings/team`,
  },
  SHARED: {
    RESET: `/reset/${PARAMS.TOKEN}`,
  },
} as const;

export const PATHNAME_MAPPING = {
  [PATHS.APP.ASSESSMENT_ELECTIVE_REHAB_INFO]:
    PAGES.APP.ASSESSMENT_ELECTIVE_REHAB_INFO,
  [PATHS.APP.ASSESSMENT_ELECTIVE_REHAB_MEDICAL]:
    PAGES.APP.ASSESSMENT_ELECTIVE_REHAB_MEDICAL,
  [PATHS.APP.ASSESSMENT_HOME_CARE_INFO]: PAGES.APP.ASSESSMENT_HOME_CARE_INFO,
  [PATHS.APP.ASSESSMENT_HOME_CARE_SEARCH]:
    PAGES.APP.ASSESSMENT_HOME_CARE_SEARCH,
  [PATHS.APP.ASSESSMENT_INFO]: PAGES.APP.ASSESSMENT_INFO,
  [PATHS.APP.ASSESSMENT_MEDICAL_SUPPLIES_CATALOGUE]:
    PAGES.APP.ASSESSMENT_MEDICAL_SUPPLIES_CATALOGUE,
  [PATHS.APP.ASSESSMENT_MEDICAL_SUPPLIES_INFO]:
    PAGES.APP.ASSESSMENT_MEDICAL_SUPPLIES_INFO,
  [PATHS.APP.ASSESSMENT_MEDICAL]: PAGES.APP.ASSESSMENT_MEDICAL,
  [PATHS.APP.ASSESSMENT_MENTAL]: PAGES.APP.ASSESSMENT_MENTAL,
  [PATHS.APP.ASSESSMENT_MOBILE_SHORT]: PAGES.APP.ASSESSMENT_MOBILE_SHORT,
  [PATHS.APP.ASSESSMENT_MOBILITY]: PAGES.APP.ASSESSMENT_MOBILITY,
  [PATHS.APP.ASSESSMENT_NEEDS]: PAGES.APP.ASSESSMENT_NEEDS,
  [PATHS.APP.ASSESSMENT_SHORT_REHAB_INFO]:
    PAGES.APP.ASSESSMENT_SHORT_REHAB_INFO,
  [PATHS.APP.ASSESSMENT_SHORT_REHAB_MEDICAL]:
    PAGES.APP.ASSESSMENT_SHORT_REHAB_MEDICAL,
  [PATHS.APP.ASSESSMENT_STEP]: PAGES.APP.ASSESSMENT_STEP,
  [PATHS.APP.ASSESSMENT_TRANSFER]: PAGES.APP.ASSESSMENT_TRANSFER,
  [PATHS.APP.ASSESSMENT_TRANSPORT_INFO]: PAGES.APP.ASSESSMENT_TRANSPORT_INFO,
  [PATHS.APP.ASSESSMENT_TRANSPORT_SEARCH]:
    PAGES.APP.ASSESSMENT_TRANSPORT_SEARCH,
  [PATHS.APP.ASSESSMENT_TRANSPORT]: PAGES.APP.ASSESSMENT_TRANSPORT,
  [PATHS.APP.AUTH_LOGIN]: PAGES.APP.AUTH_LOGIN,
  [PATHS.APP.AUTH_SSO_LOGIN]: PAGES.APP.AUTH_SSO_LOGIN,
  [PATHS.APP.AUTH_PASSWORD_RESET]: PAGES.APP.AUTH_PASSWORD_RESET,
  [PATHS.APP.CLINIC_CHANGE_PASSWORD]: PAGES.APP.CLINIC_CHANGE_PASSWORD,
  [PATHS.APP.CLINIC_DEBUG_ON_PREMISE_IMPORTS]:
    PAGES.APP.CLINIC_DEBUG_ON_PREMISE_IMPORTS,
  [PATHS.APP.CLINIC_DEBUG_ON_PREMISE_INSTALL]:
    PAGES.APP.CLINIC_DEBUG_ON_PREMISE_INSTALL,
  [PATHS.APP.CLINIC_DEBUG_ON_PREMISE_TEST]:
    PAGES.APP.CLINIC_DEBUG_ON_PREMISE_TEST,
  [PATHS.APP.CLINIC_PRINTABLE_DISCHARGE]: PAGES.APP.CLINIC_PRINTABLE_DISCHARGE,
  [PATHS.APP.CLINIC_PRINTABLE_SEARCH_PANEL]:
    PAGES.APP.CLINIC_PRINTABLE_SEARCH_PANEL,
  [PATHS.APP.CLINIC_REPORT]: PAGES.APP.CLINIC_REPORT,
  [PATHS.APP.CLINIC_USERS]: PAGES.APP.CLINIC_USERS,
  [PATHS.APP.DASHBOARD_ACTIVE]: PAGES.APP.DASHBOARD_ACTIVE,
  [PATHS.APP.DASHBOARD_ARCHIVED]: PAGES.APP.DASHBOARD_ARCHIVED,
  [PATHS.APP.DASHBOARD_DRAFT]: PAGES.APP.DASHBOARD_DRAFT,
  [PATHS.APP.DASHBOARD_FINISHED]: PAGES.APP.DASHBOARD_FINISHED,
  [PATHS.APP.DASHBOARD_PRINTABLE]: PAGES.APP.DASHBOARD_PRINTABLE,
  [PATHS.APP.PATIENT_BARTHEL_INDEX]: PAGES.APP.PATIENT_BARTHEL_INDEX,
  [PATHS.APP.PATIENT_CREATE]: PAGES.APP.PATIENT_CREATE,
  [PATHS.APP.PATIENT_EARLY_REHAB_INDEX]: PAGES.APP.PATIENT_EARLY_REHAB_INDEX,
  [PATHS.APP.PATIENT_FILES]: PAGES.APP.PATIENT_FILES,
  [PATHS.APP.PATIENT_INSURANCE]: PAGES.APP.PATIENT_INSURANCE,
  [PATHS.APP.PATIENT_MAP]: PAGES.APP.PATIENT_MAP,
  [PATHS.APP.PATIENT_PROFILE]: PAGES.APP.PATIENT_PROFILE,
  [PATHS.APP.PATIENT_REHAB_FORMS]: PAGES.APP.PATIENT_REHAB_FORMS,
  [PATHS.APP.PATIENT_REHAB_FORMS_HOME]: PAGES.APP.PATIENT_REHAB_FORMS_HOME,
  [PATHS.APP.PATIENT_SEARCH]: PAGES.APP.PATIENT_SEARCH,
  [PATHS.APP.PATIENT_TIMELINE]: PAGES.APP.PATIENT_TIMELINE,
  [PATHS.APP.PATIENT_TRANSITIONAL_CARE]: PAGES.APP.PATIENT_TRANSITIONAL_CARE,
  [PATHS.APP.REQUEST_DETAILS]: PAGES.APP.REQUEST_DETAILS,
  [PATHS.B2C.CAREPROVIDER_DETAILS]: PAGES.B2C.CAREPROVIDER_DETAILS,
  [PATHS.B2C.DASHBOARD]: PAGES.B2C.DASHBOARD,
  [PATHS.B2C.ONBOARDING_CARETERM]: PAGES.B2C.ONBOARDING_CARETERM,
  [PATHS.B2C.ONBOARDING_CARETYPE]: PAGES.B2C.ONBOARDING_CARETYPE,
  [PATHS.B2C.ONBOARDING_LOADING]: PAGES.B2C.ONBOARDING_LOADING,
  [PATHS.B2C.ONBOARDING_LOCATION]: PAGES.B2C.ONBOARDING_LOCATION,
  [PATHS.B2C.ONBOARDING_STARTDATE]: PAGES.B2C.ONBOARDING_STARTDATE,
  [PATHS.B2C.ONBOARDING_TARGET]: PAGES.B2C.ONBOARDING_TARGET,
  [PATHS.B2C.ONBOARDING_WELCOME]: PAGES.B2C.ONBOARDING_WELCOME,
  [PATHS.B2C.SPN_PROVIDER_DETAILS]: PAGES.B2C.SPN_PROVIDER_DETAILS,
  [PATHS.PROVIDER_SEARCH.ACCOUNTS]: PAGES.PROVIDER_SEARCH.ACCOUNTS,
  [PATHS.PROVIDER_SEARCH.ACTIVATION_WIZARD]:
    PAGES.PROVIDER_SEARCH.ACTIVATION_WIZARD,
  [PATHS.PROVIDER_SEARCH.AUCTION_REQUEST]:
    PAGES.PROVIDER_SEARCH.AUCTION_REQUEST,
  [PATHS.PROVIDER_SEARCH.CONSULTANT_DETAILS_PRINT]:
    PAGES.PROVIDER_SEARCH.CONSULTANT_DETAILS_PRINT,
  [PATHS.PROVIDER_SEARCH.CONSULTANT_DETAILS]:
    PAGES.PROVIDER_SEARCH.CONSULTANT_DETAILS,
  [PATHS.PROVIDER_SEARCH.GLOSSARY]: PAGES.PROVIDER_SEARCH.GLOSSARY,
  [PATHS.PROVIDER_SEARCH.LISTINGS_PRINT]: PAGES.PROVIDER_SEARCH.LISTINGS_PRINT,
  [PATHS.PROVIDER_SEARCH.LISTINGS]: PAGES.PROVIDER_SEARCH.LISTINGS,
  [PATHS.PROVIDER_SEARCH.LOGIN_WIZARD]: PAGES.PROVIDER_SEARCH.LOGIN_WIZARD,
  [PATHS.PROVIDER_SEARCH.LOGOUT]: PAGES.PROVIDER_SEARCH.LOGOUT,
  [PATHS.PROVIDER_SEARCH.PATIENT_DASHBOARD]:
    PAGES.PROVIDER_SEARCH.PATIENT_DASHBOARD,
  [PATHS.PROVIDER_SEARCH.PROVIDER_DETAILS_PRINT]:
    PAGES.PROVIDER_SEARCH.PROVIDER_DETAILS_PRINT,
  [PATHS.PROVIDER_SEARCH.PROVIDER_DETAILS]:
    PAGES.PROVIDER_SEARCH.PROVIDER_DETAILS,
  [PATHS.PROVIDER_SEARCH.TABLE_OF_CONTENTS]:
    PAGES.PROVIDER_SEARCH.TABLE_OF_CONTENTS,
  [PATHS.PROVIDER_SEARCH.ZIPCODE_SELECT]: PAGES.PROVIDER_SEARCH.ZIPCODE_SELECT,
  [PATHS.RECEIVER.AUTH_LOGIN]: PAGES.RECEIVER.AUTH_LOGIN,
  [PATHS.RECEIVER.AUTH_PASSWORD_RESET]: PAGES.RECEIVER.AUTH_PASSWORD_RESET,
  [PATHS.RECEIVER.CAREPROVIDER_NOTIFICATION_PANEL]:
    PAGES.RECEIVER.CAREPROVIDER_NOTIFICATION_PANEL,
  [PATHS.RECEIVER.CAREPROVIDER_PASSWORD_CHANGE]:
    PAGES.RECEIVER.CAREPROVIDER_PASSWORD_CHANGE,
  [PATHS.RECEIVER.CAREPROVIDER_PROFILE]: PAGES.RECEIVER.CAREPROVIDER_PROFILE,
  [PATHS.RECEIVER.DASHBOARD_ATTRIBUTED]: PAGES.RECEIVER.DASHBOARD_ATTRIBUTED,
  [PATHS.RECEIVER.DASHBOARD_DIRECT]: PAGES.RECEIVER.DASHBOARD_DIRECT,
  [PATHS.RECEIVER.DASHBOARD_IN_PROGRESS]: PAGES.RECEIVER.DASHBOARD_IN_PROGRESS,
  [PATHS.RECEIVER.DASHBOARD_NEW]: PAGES.RECEIVER.DASHBOARD_NEW,
  [PATHS.RECEIVER.DASHBOARD_OTHER]: PAGES.RECEIVER.DASHBOARD_OTHER,
  [PATHS.RECEIVER.OB_PERSONAL_CONGRATS]: PAGES.RECEIVER.OB_PERSONAL_CONGRATS,
  [PATHS.RECEIVER.OB_PERSONAL_PASSWORD]: PAGES.RECEIVER.OB_PERSONAL_PASSWORD,
  [PATHS.RECEIVER.OB_PROVIDER_ACCOUNT]: PAGES.RECEIVER.OB_PROVIDER_ACCOUNT,
  [PATHS.RECEIVER.OB_PROVIDER_COMPANY]: PAGES.RECEIVER.OB_PROVIDER_COMPANY,
  [PATHS.RECEIVER.OB_PROVIDER_CONGRATS]: PAGES.RECEIVER.OB_PROVIDER_CONGRATS,
  [PATHS.RECEIVER.OB_PROVIDER_INFO]: PAGES.RECEIVER.OB_PROVIDER_INFO,
  [PATHS.RECEIVER.OB_PROVIDER_PASSWORD]: PAGES.RECEIVER.OB_PROVIDER_PASSWORD,
  [PATHS.RECEIVER.OB_PROVIDER_SERVICES]: PAGES.RECEIVER.OB_PROVIDER_SERVICES,
  [PATHS.RECEIVER.ONBOARDING_COMPANY]: PAGES.RECEIVER.ONBOARDING_COMPANY,
  [PATHS.RECEIVER.ONBOARDING_PERSONAL]: PAGES.RECEIVER.ONBOARDING_PERSONAL,
  [PATHS.RECEIVER.ONBOARDING_PROVIDER]: PAGES.RECEIVER.ONBOARDING_PROVIDER,
  [PATHS.RECEIVER.REQUEST_BARTHEL_INDEX]: PAGES.RECEIVER.REQUEST_BARTHEL_INDEX,
  [PATHS.RECEIVER.REQUEST_DETAILS]: PAGES.RECEIVER.REQUEST_DETAILS,
  [PATHS.RECEIVER.REQUEST_EARLY_REHAB]: PAGES.RECEIVER.REQUEST_EARLY_REHAB,
  [PATHS.RECEIVER.SETTINGS_ACCOUNT]: PAGES.RECEIVER.SETTINGS_ACCOUNT,
  [PATHS.RECEIVER.SETTINGS_CAPACITY]: PAGES.RECEIVER.SETTINGS_CAPACITY,
  [PATHS.RECEIVER.SETTINGS_EXTENSIONS]: PAGES.RECEIVER.SETTINGS_EXTENSIONS,
  [PATHS.RECEIVER.SETTINGS_LEGAL]: PAGES.RECEIVER.SETTINGS_LEGAL,
  [PATHS.RECEIVER.SETTINGS_NOTIFICATIONS]:
    PAGES.RECEIVER.SETTINGS_NOTIFICATIONS,
  [PATHS.RECEIVER.SETTINGS_PARTNER_CLINICS]:
    PAGES.RECEIVER.SETTINGS_PARTNER_CLINICS,
  [PATHS.RECEIVER.SETTINGS_PROFILE]: PAGES.RECEIVER.SETTINGS_PROFILE,
  [PATHS.RECEIVER.SETTINGS_PWA_BETA]: PAGES.RECEIVER.SETTINGS_PWA_BETA,
  [PATHS.RECEIVER.SETTINGS_SEARCH]: PAGES.RECEIVER.SETTINGS_SEARCH,
  [PATHS.RECEIVER.SETTINGS_SERVICES]: PAGES.RECEIVER.SETTINGS_SERVICES,
  [PATHS.RECEIVER.SETTINGS_TEAM]: PAGES.RECEIVER.SETTINGS_TEAM,
  [PATHS.SHARED.RESET]: PAGES.SHARED.RESET,
  [PATHS.B2C.ONBOARDING_DAILY_LIVING_SUPPORT_SERVICES]:
    PAGES.B2C.ONBOARDING_DAILY_LIVING_SUPPORT_SERVICES,
} as const;

export const IGNORE_PATHS = ["/app", "/careprovider"];
