import { Session } from "core/model/session";
import useTrackingProvider from "core/model/tracker/useTrackingProvider";
import { AppType } from "core/types";
import { PlatformInfo } from "core/validationSchemas/tracking";
import { ReactNode } from "react";
import { useLoggedInAccountId } from "reduxentities/hooks";

export function TrackingProvider({
  app,
  children,
  platformInfo,
}: {
  app: AppType;
  children: ReactNode;
  platformInfo: PlatformInfo;
}) {
  const account_id = useLoggedInAccountId();
  const session = new Session();

  const { Track } = useTrackingProvider({
    account_id,
    app,
    platformInfo,
    session,
  });

  return <Track>{children}</Track>;
}

export default TrackingProvider;
